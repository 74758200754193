import _ from 'lodash'
import React, {Component} from 'react'
import {Button, Container, Menu, Visibility} from 'semantic-ui-react'
import '../../styles/nav-footer-button.css';

const menuStyle = {
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '1em',
    marginTop: '13em',
    transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}

const fixedMenuStyle = {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}

const overlayStyle = {
    float: 'left',
    margin: '0em 1em 1em 0em',
}

export class StickyNavigation extends Component {
    state = {
        menuFixed: false,
        overlayFixed: false,
    }

    handleOverlayRef = (c) => {
        const {overlayRect} = this.state
        if (!overlayRect) this.setState({overlayRect: _.pick(c.getBoundingClientRect(), 'height', 'width')})
    }

    stickOverlay = () => this.setState({overlayFixed: true})

    stickTopMenu = () => this.setState({menuFixed: true})

    unStickOverlay = () => this.setState({overlayFixed: false})

    unStickTopMenu = () => this.setState({menuFixed: false})

    render() {
        const {menuFixed, overlayFixed, overlayRect} = this.state

        return (
            <div id='navigation'>
                {/* Heads up, style below isn't necessary for correct work of example, simply our docs defines other
            background color.
          */}
                <style>{`
          html, body {
            background: #fff;
          }
        `}</style>

                {/* Attaching the top menu is a simple operation, we only switch `fixed` prop and add another style if it has
            gone beyond the scope of visibility
          */}
                <Visibility
                    onBottomPassed={this.stickTopMenu}
                    onBottomVisible={this.unStickTopMenu}
                    once={false}
                >
                    <Menu
                        borderless
                        fixed={menuFixed ? 'top' : undefined}
                        style={menuFixed ? fixedMenuStyle : menuStyle}
                    >
                        <Container style={{
                            justifyContent: 'center',
                            fontFamily: 'Quicksand'
                        }}>
                            <Menu.Item>
                                <Button href='#about' primary basic className="nav-footer-button">About</Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button href='#products' primary basic className="nav-footer-button">Products</Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button href='#capabilities' primary basic
                                        className="nav-footer-button">Capabilities</Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button href='#careers' primary basic className="nav-footer-button">Careers</Button>
                            </Menu.Item>
                        </Container>
                    </Menu>
                </Visibility>

                <Container text>

                    {/* Example with overlay menu is more complex, SUI simply clones all elements inside, but we should use a
              different approach.
              An empty Visibility element controls the need to change the fixing of element below, it also uses height
              and width params received from its ref for correct display.
            */}
                    <Visibility
                        offset={80}
                        once={false}
                        onTopPassed={this.stickOverlay}
                        onTopVisible={this.unStickOverlay}
                        style={overlayFixed ? {...overlayStyle, ...overlayRect} : {}}
                    />

                </Container>

            </div>
        )
    }
}
