export const statisticsData = [
    {
        "id": "001",
        "label": "Cylinders",
        "labelUnit": "",
        "value": "350K+",
        "duration": "0.1"
    },
    {
        "id": "002",
        "label": "Employees",
        "labelUnit": "",
        "value": "580+",
        "duration": "0.1"
    },
    {
        "id": "003",
        "label": "Area",
        "labelUnit": "\u33A1",
        "value": "94 018",
        "duration": "1"
    },
    {
        "id": "004",
        "label": "In business",
        "labelUnit": " years",
        "value": "45",
        "duration": "3"
    }
]