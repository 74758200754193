import React, {useEffect, useState} from 'react'
import {Divider, Grid} from 'semantic-ui-react'
import {statisticsData} from './data.js'


export class Statistics extends React.Component {
    render() {
        return (
            <Grid container stackable verticalAlign='middle' style={{margin: '50px'}}>
                <Divider style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexGrow: 1
                }}></Divider>
                <Grid.Row verticalAlign='middle' columns={4} style={{marginTop: '60px', marginBottom: '60px'}}>
                    {statisticsData.map(count => <Grid.Column key={count.id}> <Statistic data={count}/></Grid.Column>)}
                </Grid.Row>
                <Divider></Divider>
            </Grid>
        )
    }
}

const Statistic = props => {
    const {duration, label, labelUnit, value} = props.data
    const [count, setCount] = useState("0")

    useEffect(() => {
        let start = 0;
        const end = parseInt(value.substring(0, 3))
        if (start === end) return;
        let totalMilSecDur = parseInt(duration);
        let incrementTime = (totalMilSecDur / end) * 1000;
        let timer = setInterval(() => {
            start += 1;
            setCount(String(start) + value.substring(3))
            if (start === end) clearInterval(timer)
        }, incrementTime);
    }, [value, duration]);

    return (
        <Grid style={{padding: '20px 5px 20px 5px'}}>
            <Grid.Row centered style={{margin: 0, padding: '0px, 0px, 12px, 0px', flexWrap: 'nowrap'}} columns={2}>
                <span style={{fontFamily: 'Montserrat', fontSize: '45px', fontWeight: '800', margin: 0}}>{count}</span>
                <span style={{
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }}>{labelUnit}</span>
            </Grid.Row>
            <Divider style={{height: '2px', backgroundColor: '#435584', margin: '0px, 60px, 0px, 60px'}}/>
            <Grid.Row centered style={{paddingTop: '12px'}}>
                <p style={{fontFamily: 'Montserrat'}}>{label}</p>
            </Grid.Row>
        </Grid>
    )
}
