import React from 'react'
import {Button, GridRow, Container, Grid, Segment} from 'semantic-ui-react'

export const Footer = (props) => {
    return (
        <Segment id="FooterSegment" basic style={{width: '100%', backgroundColor: "#1b1c1d"}} vertical>
            <Grid container stackable verticalAlign='middle' id="FooterGrid" style={{width: '100%'}}>
                <GridRow
                    style={{width: '100%'}}>
                    <FooterLayout showContacts={props.showContacts} className="ui computer only grid"/>
                </GridRow>
                {/*<GridRow*/}
                {/*    // only='computer'*/}
                {/*    style={{width: '100%'}}>*/}
                {/*    <FooterComputerTablet showContacts={props.showContacts} className="ui computer only grid"/>*/}
                {/*</GridRow>*/}
                {/*<GridRow only='tablet'>*/}
                {/*    <FooterComputerTablet showContacts={props.showContacts} className="ui tablet only grid"/>*/}
                {/*</GridRow>*/}
                {/*<GridRow only='mobile'>*/}
                {/*    <FooterMobile showContacts={props.showContacts} className="ui mobile only grid"/>*/}
                {/*</GridRow>*/}
            </Grid>
        </Segment>
    )
}

const FooterLayout = (props) => {
// const FooterComputerTablet = (props) => {
    return (
        <Segment id='footer' inverted vertical style={{padding: '1.5em 0em', width: '100%'}}>
            <Container style={{width: '100%'}}>
                <Grid style={{width: '100%'}}>
                    <Grid.Row columns={4} centered style={{width: '100%'}}>
                        <Grid.Column textAlign="left">
                            <Button primary basic onClick={props.showContacts}
                                // style={{padding: '0.8em', whiteSpace: 'nowrap'}}
                                    className="nav-footer-button">
                                Contact us
                            </Button>
                        </Grid.Column>
                        <Grid.Column textAlign="left">
                            <Button primary basic as='a' href="https://g.page/Kyashif?share" target="_blank"
                                    rel="noopener noreferrer"
                                // style={{padding: '0.8em', whiteSpace: 'nowrap'}}
                                    className="nav-footer-button">
                                Visit us
                            </Button>
                        </Grid.Column>
                        <Grid.Column textAlign="left">
                            <Button primary basic as='a' href="https://maps.app.goo.gl/oTkJzwKXRdCC5MjdA"
                                    target="_blank" rel="noopener noreferrer"
                                // style={{padding: '0.8em', whiteSpace: 'nowrap'}}
                                    className="nav-footer-button"
                            >
                                Take a virtual tour
                            </Button>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Button href="#top" primary basic as='a'
                                // style={{padding: '0.8em', whiteSpace: 'nowrap'}}
                                    className="nav-footer-button"
                            >
                                Top</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    )
}

// const FooterMobile = (props) => {
//     return (
//         <Segment id='footer' inverted vertical style={{padding: '1.5em 0em', width: '100%'}}>
//             <Container style={{width: '100%'}}>
//                 <Grid style={{width: '100%', margin: '0'}}>
//                     <Grid.Row
//                         style={{
//                             width: '100%',
//                             display: 'flex',
//                             justifyContent: 'space-between',
//                             flexWrap: 'wrap',
//                             padding: '0em 0em 1em 0em',
//                             grow: '1',
//                             minWidth: '20.625em'
//                         }}>
//                         <Button primary basic size='small' onClick={props.showContacts}
//                                 style={{padding: '0.8em', whiteSpace: 'nowrap'}}>
//                             Contact us
//                         </Button>
//                         <Button primary basic size='small' as='a' href="https://g.page/Kyashif?share"
//                                 target="_blank" rel="noopener noreferrer"
//                                 style={{padding: '0.8em', whiteSpace: 'nowrap'}}>
//                             Visit us
//                         </Button>
//                         <Button primary basic size='small' as='a' href="https://maps.app.goo.gl/oTkJzwKXRdCC5MjdA"
//                                 target="_blank" rel="noopener noreferrer"
//                                 style={{padding: '0.8em', whiteSpace: 'nowrap'}}>
//                             Take a virtual tour
//                         </Button>
//                     </Grid.Row>
//                     <Grid.Row style={{padding: '1em 0em 0em 0em'}}>
//                         <Grid.Column style={{flex: '1 1 auto', textAlign: 'center', minWidth: '100px',}}>
//                             <Button href="#top" primary basic size='small'
//                                     style={{padding: '0.8em 5em', whiteSpace: 'nowrap'}}>Top</Button>
//                         </Grid.Column>
//                     </Grid.Row>
//                 </Grid>
//             </Container>
//         </Segment>
//     )
// }
