import React from 'react'
import {Grid, Image} from 'semantic-ui-react'
import EUProjectBigScreen from '../../img/EUProjectBigScreen.png'
import EUProjectMobile from '../../img/EUProjectMobile.png'


export const EUProject = () => {
    return (
        <>
            < EUProjectComputerTablet className="ui computer only grid"/>
            < EUProjectComputerTablet className="ui tablet only grid"/>
            < EUProjectMobileComponent className="ui mobile only grid"/>
        </>
    )
}

const EUProjectComputerTablet = props => {
    return (
        <Grid.Row centered className={props.className} style={{margin: '4em 0em 0em 0em'}} columns={1}>
            < EUProjectComponent src={EUProjectBigScreen}/>
        </Grid.Row>
    )
}

const EUProjectMobileComponent = props => {
    const className = props.className
    return (
        <Grid.Row className={className} style={{margin: '4em 0em 0em 0em'}} columns={1}>
            < EUProjectComponent src={EUProjectMobile}/>
        </Grid.Row>
    )
}

const EUProjectComponent = props => {
    const src = props.src
    return (
        <Grid.Column>
            <Image centered id=''
                   alt={`${src}`}
                   src={src}
                // style={{width:'10.5em', height:'10.5em'}}
            />
        </Grid.Column>
    )
}