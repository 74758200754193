import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Button,
    Grid,
    Header,
    Segment,
} from 'semantic-ui-react'
import '../../styles/custom.css';


export class Careers extends React.Component {
    render() {
        return (
            <Segment id={"CareersSegment"} basic style={{padding: '4em 0em 4em 0em', background: '#004A8D'}} vertical>
                <Grid container stackable centered verticalAlign='middle' id={"CareersGrid"}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce={true}>
                        <Grid.Row centered verticalAlign='middle' id={"ContainerRow"}
                        >
                            <Grid.Column centered verticalAlign='middle' id={"ContainerCol"}>
                                <Header as='h3' id="Header" style={{
                                    fontSize: '3.5em',
                                    fontFamily: 'Montserrat',
                                    padding: '1em 0em 1em 0em',
                                    color: 'white',
                                }}>Careers</Header>
                                <Grid.Row centered verticalAlign='middle'
                                          style={{padding: '2em 0em 5em 0em',}}>
                                    <Grid.Column centered verticalAlign='middle'>
                                        <Grid.Row centered verticalAlign='middle'
                                                  style={{padding: '2em 0em 2em 0em',}}>
                                            {/*<Button*/}
                                            {/*    // primary*/}
                                            {/*    // basic*/}
                                            {/*    className="custom-button"*/}
                                            {/*    href="https://www.jobs.bg/company/kyashif"*/}
                                            {/*    id={'join-us-button'}*/}
                                            {/*    target="_blank"*/}

                                            {/*>*/}
                                            {/*    Join us*/}
                                            {/*</Button>*/}
                                            <JoinUsButton/>
                                        </Grid.Row>
                                        <Description/>
                                    </Grid.Column>
                                </Grid.Row>


                            </Grid.Column>
                        </Grid.Row>
                    </ScrollAnimation>
                </Grid>
            </Segment>
        )
    }
}

const Description = () => {
    return (
        <p style={{fontSize: '1.0em', color: '#ffffff'}}>
            Explore open roles that match your interests and skills.
        </p>
    )
}

const JoinUsButton = () => {
    // Define the gtag_report_conversion function inside the component
    const gtag_report_conversion = (url) => {
        var callback = function () {
            if (typeof (url) !== 'undefined') {
                window.location = url;
            }
        };
        // Use window.gtag to ensure it's recognized
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11258023337/_YuDCPjT3fYZEKmbn_gp',
            'value': 1.0,
            'currency': 'BGN',
            'event_callback': callback
        });
        return false;
    };

    return (
        <Button
            className="custom-button"
            id="join-us-button"
            target="_blank"
            onClick={() => gtag_report_conversion('https://www.jobs.bg/company/kyashif')}
        >
            Join us
        </Button>
    );
};